import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export const getDuration = (start, end) => {
  if (start !== "" && end !== "") {
    const endDate = dayjs(end);
    const startDate = dayjs(start);

    const days = endDate.diff(startDate, "d");
    const hours = endDate.diff(startDate, "h") - days * 24;
    const minutes =
      endDate.diff(startDate, "m") - (days * 24 * 60 + hours * 60);
    return `${days} days ${hours} hours ${minutes} minutes`;
  }

  return "";
};

export const getUTCTime = (timeFormat = "") => {
  return dayjs.utc().format(timeFormat);
};
