<template>
  <div>
    <DefaultHeader />
    <div class="container">
      <main>
        <keep-alive>
          <slot />
        </keep-alive>
      </main>
    </div>
    <DefaultFooter />
  </div>
</template>
<script>
import DefaultFooter from './DefaultFooter.vue';
import DefaultHeader from './DefaultHeader.vue';
export default {
  components: { DefaultHeader, DefaultFooter },
  name: 'Default',
  data() {
    return {};
  },
  created() {},
};
</script>
