import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./plugins";
import { keycloak } from "./plugins/keycloak";

Vue.config.productionTip = false;

keycloak
  .init({
      onLoad: "login-required",
      checkLoginIframe: false // this is done to enable loading of iframe for KC recaptcha - https://stackoverflow.com/questions/68509296/keycloak-js-initialization-failed-because-an-ancestor-violates-the-content-secur
  })
  .then((auth) => {
    if (!auth) {
      window.location.reload();
      localStorage.removeItem("vue-token");
      localStorage.removeItem("vue-refresh-token");
      localStorage.removeItem("vue-token-parsed");
    } else {
      console.log(keycloak.idTokenParsed);
      localStorage.setItem("vue-token", keycloak.token);
      localStorage.setItem("vue-username", keycloak.idTokenParsed.name);
      localStorage.setItem("vue-refresh-token", keycloak.refreshToken);

      localStorage.setItem("cl-account-id", keycloak.idTokenParsed.account_id);
      localStorage.setItem("cl-space-name", keycloak.idTokenParsed.space_name);
    }

    new Vue({
      router,
      render: (h) => h(App),
    }).$mount("#app");

    setInterval(() => {
      keycloak
        .updateToken(70)
        .then((refreshed) => {
          if (refreshed) {
            console.log(`Token refreshed ${refreshed}`);
            localStorage.setItem("vue-token", keycloak.token);
            localStorage.setItem("vue-refresh-token", keycloak.refreshToken);
          } else {
            console.log(
              "Token not refreshed, valid for " +
                Math.round(
                  keycloak.tokenParsed.exp +
                    keycloak.timeSkew -
                    new Date().getTime() / 1000
                ) +
                " seconds"
            );
          }
        })
        .catch((e) => {
          console.log(`Failed to refresh token ${e}`);
        });
    }, 60000);
  })
  .catch((e) => {
    console.log(`Authenticated Failed ${e}`);
  });
