<template>
  <header class="mb-4">
    <div class="container">
      <nav class="row navbar navbar-expand-lg navbar-dark">
        <div class="col-md-2">
          <router-link
            to="/"
            class="
              navbar-brand
              align-items-center
              mb-3 mb-md-0
              me-md-auto
              text-dark text-decoration-none
            "
          >
            <img class="logo" src="@/assets/img/logo.png" />
          </router-link>
          <button
            class="navbar-toggler float-right"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navBarToggle"
            aria-controls="navBarToggle"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
        </div>
        <div class="col-md-10">
          <div class="collapse navbar-collapse" id="navBarToggle">
            <ul class="navbar-nav nav nav-pills ziqui-header">
             <li class="nav-item" v-for="m of menus" :key="m.id">
                <a :href="m.link" class="nav-link" :class="m.css ? m.css : ''">
                  {{ m.text }}
                </a>
              </li>
              <li class="nav-item">
                <div class="dropdown">
                  <a
                    class="nav-link btn-account dropdown-toggle"
                    href="#"
                    role="button"
                    id="dropdownAccountMenu"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <UTCTimer>
                      <span class="avatar">{{ avatar }}</span>
                    </UTCTimer>
                  </a>

                  <ul
                    class="dropdown-menu header shadow-sm"
                    aria-labelledby="dropdownAccountMenu"
                  >
                    <li>
                      <a
                        class="dropdown-item"
                        href="https://identity.ziqni.com/realms/ziqni/account/#/personal-info"
                        >My Account</a
                      >
                    </li>
                    <li>
                      <a class="dropdown-item" href="#" @click="logout"
                        >Logout</a
                      >
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </header>
</template>
<script>
import UTCTimer from './UTCTimer.vue';
import { getInitials } from '../../util/stringUtils';
export default {
  components: { UTCTimer },
  name: 'DefaultHeader',
  data() {
    return {
      menus: [],
      avatar: '',
    };
  },
  created() {
    this.menus = require('../../api/menus.link.api.json');
    this.menus = this.menus.filter((x) => x.hideOnHeader === false);
    this.avatar = getInitials(localStorage.getItem('vue-username'));
    console.log(this.avatar);
  },
  methods: {
    logout() {
      this.$keycloak.logout({
        redirectUri: 'https://ziqni.com'
      });
    },
  },
};
</script>
