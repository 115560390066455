<template>
  <section id="app">
    <component :is="layout" ref="componentRef">
      <router-view ref="childRef"></router-view>
    </component>
  </section>
</template>

<script>
import { AppLayout } from "./util/contants";
export default {
  computed: {
    layout() {
      return this.$route.meta.layout || AppLayout.Default;
    },
  },
  beforeMount() {
    // let _this = this;
    // let _document = document;
    // this.$prismic.client.query(
    //   [
    //     this.$prismic.Predicates.any("document.type", ["launchpad_theme"]),
    //     this.$prismic.Predicates.any("document.tags", ["ziqni.app"]),
    //   ],
    //   null,
    //   (error, document) => {
    //     if (error) {
    //       console.error(`Received error ${error}`);
    //       return;
    //     }
    //     _this.$data.topMenu["logo"] = document.results[0].data["logo"];
    //     _this.$data.topMenu["items"] = document.results[0].data["top-menu"];
    //     _this.$data.bottomMenu["logo"] =
    //       document.results[0].data["footer-logo"];
    //     _this.$data.bottomMenu["items"] =
    //       document.results[0].data["bottom-menu"];
    //     _document.title = document.results[0].data["site-title"].text;
    //     // var favicon = _document.querySelector('link[rel="icon"]')
    //     // favicon.href=document.results[0].data.favicon.url
    //     this.isReady = true;
    //   }
    // );
  },
};
</script>
