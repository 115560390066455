import axios from "axios";
import Vue from "vue";

const instance = axios.create({
  baseURL: process.env.VUE_APP_ZIQNI_ADMIN_API,
  timeout: 6000,
});

instance.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("vue-token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);
Vue.prototype.$http = instance;
