import Vue from "vue";
import VueRouter from "vue-router";
import { AppLayout } from "../util/contants";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Home.vue"),
    meta: {
      layout: AppLayout.Default,
    },
  },
  {
    path: "/create-project",
    name: "CreateProject",
    component: () => import("@/views/CreateProject.vue"),
    meta: {
      layout: AppLayout.Default,
    },
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
