import Vue from "vue";
import PrismicVue from "@prismicio/vue";
import linkResolver from "./link-resolver";
import htmlSerializer from "./html-serializer";
Vue.use(PrismicVue, {
  endpoint: process.env.VUE_APP_PRISMIC_CMS_ENDPOINT,
  linkResolver,
  htmlSerializer,
});

/**
 * List of available components and use cases:
Component	Prismic type	Use case	Example use
prismic-rich-text	Rich Text, Title	Used when you need formatting fir text (bold, italic, embeds...)	<prismic-rich-text :field="field.description" wrapper="div" :htmlSerializer="myLocalSerializer"/>
prismic-link	Link	Used to resolve links stored in your Prismic content.	<prismic-link :field="menuLink.link">My link</prismic-link>
prismic-image	Image	Used to display images stored in Prismic.	<prismic-image :field="fields.logo" />
prismic-embed	Embed	Used to display embeds stored in Prismic.	<prismic-embed :field="fields.embed" wrapper="div" />
 * 
 */
