<template>
  <footer class="mt-auto pt-5">
    <div class="container text-center">
      <div class="row">
        <div class="col-lg-12 pt-2 pb-5">
          <img src="@/assets/img/footer-logo.png" />
        </div>
        <div class="col-lg-12">
          <div class="d-flex justify-content-center py-3">
            <ul
              class="nav justify-content-center pb-3 mb-3 nav nav-pills footer"
            >
              <li class="nav-item" v-for="m of menus" :key="m.id">
                <a :href="m.link" class="nav-link" :class="m.css ? m.css : ''">
                  {{ m.text }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "DefaultFooter",
  data() {
    return {
      menus: [],
    };
  },
  created() {
    this.menus = require("../../api/menus.link.api.json");
    this.menus = this.menus.filter((x) => x.hideOnFooter === false);
  },
};
</script>
