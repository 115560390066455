<template>
  <span class="utc-timer">
    <div style="display: inline-block; width: 4rem">{{ time }}</div>
    UTC
    <slot />
  </span>
</template>

<script>
import { getUTCTime } from '../../util/timeUtils';

export default {
  data() {
    return {
      time: getUTCTime('HH:mm:ss'),
    };
  },
  mounted() {
    setInterval(this.getNow, 1000);
  },
  methods: {
    getNow() {
      this.time = getUTCTime('HH:mm:ss');
    },
  },
};
</script>
