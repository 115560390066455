import Keycloak from "keycloak-js";

export const keycloak = Keycloak({
  onLoad: "login-required",
  realm: process.env.VUE_APP_KEYCLOAK_REALM,
  url: process.env.VUE_APP_KEYCLOAK_URL,
  clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID
});

import Vue from "vue";
Vue.prototype.$keycloak = keycloak;
